// extracted by mini-css-extract-plugin
export var footerMenuWidth = "174px";
export var footerMenuGutter = "30px";
export var wideFooterMenuWidth = "314px";
export var verticalCenter = "desktop-footer-module--verticalCenter--NC-AF";
export var platformContainer = "desktop-footer-module--platformContainer--oFZqR desktop-footer-module--verticalCenter--NC-AF";
export var platformTitle = "desktop-footer-module--platformTitle--9bF9u";
export var platformFooterMsg = "desktop-footer-module--platformFooterMsg--8wjf7";
export var platformGroups = "desktop-footer-module--platformGroups--5xyMe";
export var platformGroup = "desktop-footer-module--platformGroup--M9oE1";
export var platformGroupTitle = "desktop-footer-module--platformGroupTitle--BelEG";
export var platformGroupButtons = "desktop-footer-module--platformGroupButtons--f0g94";
export var platformImgButton = "desktop-footer-module--platformImgButton--7UwxQ";
export var platformButton = "desktop-footer-module--platformButton--In9ma";
export var footerMenusAndInfoContainer = "desktop-footer-module--footerMenusAndInfoContainer--Xk7Hh desktop-footer-module--verticalCenter--NC-AF";
export var px10 = "desktop-footer-module--px10--G3qPG";
export var footerMenusContainer = "desktop-footer-module--footerMenusContainer--f9v5t";
export var footerMenusColumn = "desktop-footer-module--footerMenusColumn--H7sMK";
export var footerMenuLabel = "desktop-footer-module--footerMenuLabel--yMiuN";
export var wideFooterMenusColumn = "desktop-footer-module--wideFooterMenusColumn---+ei4 desktop-footer-module--footerMenusColumn--H7sMK";
export var companyInfoContainer = "desktop-footer-module--companyInfoContainer--BSykh";
export var logoContainer = "desktop-footer-module--logoContainer--yhRIp";
export var companyLogo = "desktop-footer-module--companyLogo--vplYB";
export var copyRight = "desktop-footer-module--copyRight--mAUYk";
export var companyInfoDetail = "desktop-footer-module--companyInfoDetail--dGBo3";
export var footerLangSelector = "desktop-footer-module--footerLangSelector--xNMQX";