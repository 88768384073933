// extracted by mini-css-extract-plugin
export var dropDownMenu = "desktop-header-module--dropDownMenu--66q4s";
export var dropDownMenuTitle = "desktop-header-module--dropDownMenuTitle--DDjQH";
export var floatingMenuContainer = "desktop-header-module--floatingMenuContainer--My+Ku";
export var subMenu = "desktop-header-module--subMenu--Vv2qp";
export var subMenuIcon = "desktop-header-module--subMenuIcon--2W0Z5";
export var subMenuTitle = "desktop-header-module--subMenuTitle--KihAV";
export var subMenuDesc = "desktop-header-module--subMenuDesc--Es7mm";
export var headerContainer = "desktop-header-module--headerContainer--62bQj";
export var headerPlaceholder = "desktop-header-module--headerPlaceholder--EolJX";
export var menuContainer = "desktop-header-module--menuContainer--EPfrf";
export var singleMenu = "desktop-header-module--singleMenu--M0uEF";
export var padding = "desktop-header-module--padding--XQ9wl";
export var biLogo = "desktop-header-module--biLogo--KcmfO";
export var loginButton = "desktop-header-module--loginButton--bwkKE";
export var whiteContainer = "desktop-header-module--whiteContainer--5PAqq";