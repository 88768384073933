// extracted by mini-css-extract-plugin
export var installButtonHeight = "54px";
export var platformsContainer = "mobile-footer-module--platformsContainer--NQqBz";
export var platformsTitle = "mobile-footer-module--platformsTitle--cnT9V";
export var platformsDesc = "mobile-footer-module--platformsDesc--WaUUq";
export var verticalCenter = "mobile-footer-module--verticalCenter--2qx8S";
export var startNowContainer = "mobile-footer-module--startNowContainer--lxx3f mobile-footer-module--verticalCenter--2qx8S";
export var startNowTitle = "mobile-footer-module--startNowTitle--oFkgq";
export var footerMenusAndInfoContainer = "mobile-footer-module--footerMenusAndInfoContainer--CxbOS";
export var footerMenu = "mobile-footer-module--footerMenu--rYwjR";
export var footerMenuLabel = "mobile-footer-module--footerMenuLabel--c1CYw";
export var footerSubMenu = "mobile-footer-module--footerSubMenu--HqdLY";
export var langButton = "mobile-footer-module--langButton--CF+SS";
export var companyLogo = "mobile-footer-module--companyLogo--L9Bqb";
export var companyInfoContainer = "mobile-footer-module--companyInfoContainer--5xCKw";
export var companyInfoDetail = "mobile-footer-module--companyInfoDetail--oTSYu";
export var policyLink = "mobile-footer-module--policyLink--ekcLe";
export var tosPrivacyLink = "mobile-footer-module--tosPrivacyLink--BpYWG";