// extracted by mini-css-extract-plugin
export var headerContainer = "mobile-header-module--headerContainer--jP7xk";
export var headerPlaceholder = "mobile-header-module--headerPlaceholder--PA8ts";
export var whiteContainer = "mobile-header-module--whiteContainer--r-S-S";
export var biLogo = "mobile-header-module--biLogo--kppR7";
export var menuBtn = "mobile-header-module--menuBtn--E7zer";
export var menuBtnLine = "mobile-header-module--menuBtnLine--bNrsJ";
export var isOpen = "mobile-header-module--isOpen--2zugF";
export var disableScrolling = "mobile-header-module--disableScrolling--wIasu";
export var menuContainer = "mobile-header-module--menuContainer--8JIzR";
export var dropDownContainer = "mobile-header-module--dropDownContainer--zx2ye";
export var dropDownButton = "mobile-header-module--dropDownButton--pjphx";
export var dropDownTitle = "mobile-header-module--dropDownTitle--uWLdC";
export var dropDownActive = "mobile-header-module--dropDownActive--Wispp";
export var dropDownIcon = "mobile-header-module--dropDownIcon--UUL-T";
export var dropDownSubMenus = "mobile-header-module--dropDownSubMenus--yHxvd";
export var dropDownItem = "mobile-header-module--dropDownItem--0wEx6";
export var singleMenu = "mobile-header-module--singleMenu--oHu5S";
export var langButton = "mobile-header-module--langButton--EjaBM";
export var selected = "mobile-header-module--selected--KZrID";
export var startNowContainer = "mobile-header-module--startNowContainer--NHroy";
export var appDownloadButton = "mobile-header-module--appDownloadButton--joy4y";
export var startNowButton = "mobile-header-module--startNowButton--mP2D6";
export var controls = "mobile-header-module--controls--W6MLq";
export var appDownloadButtonInHeader = "mobile-header-module--appDownloadButtonInHeader--Frg4S";